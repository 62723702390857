

import { createOutline } from 'ionicons/icons';
import { IonIcon, IonItem, IonLabel } from "@ionic/vue";
import {computed} from "vue";
import {useStore} from "vuex";

export default {
    name: 'TemplateSelection',
    components: {
      IonItem,
      IonLabel,
      IonIcon  
    },
    props: {
        templateName: { 
            type: String,
            required: true
        }
    },
    setup() {
        const store = useStore();
        
        return {
            language: computed(() => store.getters['app/language']),
            createOutline
        }
    }
}
