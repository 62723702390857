<template>
    <div class="form-section-header">
        <p>{{ language.templateSelection }}</p>
    </div>
    <ion-item lines="full" mode="md">
        <ion-label>
            <h2>{{ templateName }}</h2>
        </ion-label>
        <div @click="$emit('edit');" class="icon-container"><ion-icon :icon="createOutline" class="edit-icon"></ion-icon></div>
    </ion-item>
</template>

<script lang="ts">

import { createOutline } from 'ionicons/icons';
import { IonIcon, IonItem, IonLabel } from "@ionic/vue";
import {computed} from "vue";
import {useStore} from "vuex";

export default {
    name: 'TemplateSelection',
    components: {
      IonItem,
      IonLabel,
      IonIcon  
    },
    props: {
        templateName: { 
            type: String,
            required: true
        }
    },
    setup() {
        const store = useStore();
        
        return {
            language: computed(() => store.getters['app/language']),
            createOutline
        }
    }
}
</script>

<style scoped>
</style>