<template>
    <ion-page>
        <ion-content :fullscreen="true" class="ion-no-padding">
            <TemplateSelection :templateName="journeySet.templateName" @edit="editTemplate" />
            <div class="form-section-header">
                <p>{{ language.categorySelection }}</p>
            </div>
            <ion-item lines="full" mode="md">
                <ion-icon slot="start" :src="language.quadrants[quadrant]?.icon" color="primary" class="journey-set-type-icon" />
                <ion-label>
                    <h2>{{ journeySetTypes.get(journeySet.journeySetTypeId) }}</h2>
                </ion-label>
                <div @click="editCategory"><ion-icon :icon="createOutline" class="edit-icon"></ion-icon></div>
            </ion-item>
            <div class="form-section-header">
                <p>{{ language.journeySetInfo }}</p>
            </div>
            <ion-item lines="full" mode="md">
                <ion-label position="stacked">{{ language.title }}</ion-label>
                <ion-input autocomplete="off" :placeholder="language.title" v-model="journeySet.title" />
            </ion-item>
            <ion-item lines="full" mode="md">
                <ion-label position="stacked">{{ language.journeySetKeyword }}</ion-label>
                <ion-input autocomplete="off" :placeholder="language.keyword" v-model="journeySet.keyword" />
            </ion-item>
            <ion-item v-if="!selectedIcon.data" lines="full" mode="md">
                <button class="custom-button button-outline" @click="openImageSelectModal">{{ language.journeySetIcon }}</button>
            </ion-item>
            <ion-item v-else lines="full" mode="md">
                <ion-icon slot="start" :src="selectedIcon.data" class="icon-preview"></ion-icon>
                <ion-label>
                    <h2>{{ selectedIcon.filename }}</h2>
                </ion-label>
                <div class="icon-container" @click="removeImg"><ion-icon :icon="closeOutline" class="edit-forward"></ion-icon></div>
            </ion-item>
        </ion-content>
        <FixedFooter :buttons="footerButtons"/>
    </ion-page>
</template>

<script lang="ts">

import {IonContent, IonIcon, IonInput, IonItem, IonLabel, IonPage, IonToggle, IonImg, IonRow} from '@ionic/vue';
import {store} from "@/store/Store";
import {execute} from "@/mixins/LoadingMixin";
import {computed, defineComponent, ref} from "vue";
import { createOutline, ellipseOutline, closeOutline } from 'ionicons/icons';
import {createJourneySet, getJourneySet, updateJourneySet} from "@/services/JourneySetService";
import {JourneySet} from "@/models/JourneySetModel";
import {JOURNEY_SET_TYPES, JourneySetTypeId} from "@/models/JourneySetTypes";
import router from "@/router";
import {openSelectImageModal} from "@/services/SelectModalService";
import {IMAGE_GROUP_TYPES, ImageGroupId} from "@/models/ImageGroupTypes";
import {Image} from "@/models/ImageModel";
import FixedFooter from "@/components/FixedFooter.vue";
import {presentValidationAlert} from "@/services/Notify";
import {getTemplate} from "@/services/TemplateService";
import {useStore} from "vuex";
import TemplateSelection from "@/components/TemplateSelection.vue";
import {getImage} from "@/services/ImagesService";
import {uuid4} from "@capacitor/core/dist/esm/util";
import {FooterButtonsBuilder} from "@/mixins/FooterButtonsBuilder";

export default defineComponent({
    name: 'SetJourneySetInfo',
    components: {
        IonContent,
        IonPage,
        IonItem,
        IonLabel,
        IonIcon,
        IonInput,
        FixedFooter,
        TemplateSelection
    },
    props: {
        journeySetId: {
            type: String,
            required: true,
            default: ''
        },
        templateId: {
            type: String,
            required: false,
            default: ''
        },
        categoryId: {
            type: String,
            required: false,
            default: ''
        }
    },
    async ionViewWillEnter() {
        await store.dispatch('app/setPageTitle', this.language.journeySetCreation);
        await execute(async () => {
            if (this.editingExisting) {
                this.journeySet = await getJourneySet(this.journeySetId);
                if (this.journeySet.imageId) {
                    this.selectedIcon = await getImage(this.journeySet.imageId);
                } else {
                    this.selectedIcon = {} as Image;
                }
            } else {
                const template = await getTemplate(this.templateId);
                this.journeySet.templateId = template.id;
                this.journeySet.templateName = template.name;
                this.journeySet.journeySetTypeId = this.categoryId;
                this.journeySet.title = '';
                this.journeySet.keyword = '';
                this.journeySet.imageId = '';
                this.journeySet.imageData = '';
                this.selectedIcon = {} as Image;
            }
        }, this.language.errors.gettingJourneySet);
    },
    setup(props) {
        const journeySet = ref({} as JourneySet);
        const selectedIcon = ref({} as Image);
        const editingExisting = computed(() => props.journeySetId.trim().toLowerCase() !== 'create');
        const store = useStore();
        const language = computed(() => store.getters['app/language']);

        const editTemplate = () => {
            if (editingExisting.value) {
                router.push({ name: 'journeySetTemplate', params: { journeySetId: props.journeySetId }});
            } else {
                router.push({ name: 'journeySetTemplate', params: { journeySetId: 'create' }});
            }
        }

        const editCategory = () => {
            if (editingExisting.value) {
                router.push({ name: 'journeySetCategory', params: { journeySetId: props.journeySetId }});
            } else {
                router.push({ name: 'journeySetCategory', params: { journeySetId: 'create', templateId: props.templateId, categoryId: props.categoryId }});
            }
        }

        const openImageSelectModal = async () => {
            const imageSelect = await openSelectImageModal(IMAGE_GROUP_TYPES.get(ImageGroupId.JourneySetIcon) || '', ImageGroupId.JourneySetIcon);
            const { data } = await imageSelect.onWillDismiss();
            journeySet.value.imageId = data.id;
            selectedIcon.value = data;
        }

        const removeImg = () => {
            journeySet.value.imageId = '';
            selectedIcon.value = {} as Image;
        }

        return {
            language,
            journeySet,
            journeySetTypes: JOURNEY_SET_TYPES,
            createOutline,
            ellipseOutline,
            closeOutline,
            editTemplate,
            editCategory,
            openImageSelectModal,
            selectedIcon,
            removeImg,
            editingExisting,
            quadrant: computed(() => {
                const journeyTypes = JourneySetTypeId as { [key: string]: string };
                for (const quadrant in journeyTypes) {
                    if (journeyTypes[quadrant] === journeySet.value.journeySetTypeId) {
                        return quadrant;
                    }
                }
                return '';
            }),
            footerButtons: computed(() => {
                return new FooterButtonsBuilder()
                    .addClearBackButton()
                    .addPrimaryNextButton(
                        async () => {
                            let errorMessage = '';
                            if (!journeySet.value.title || journeySet.value.title.trim() === '') {
                                errorMessage = language.value.validation.enterTitle;
                            } else if (!journeySet.value.keyword || journeySet.value.keyword?.trim() === '') {
                                errorMessage = language.value.validation.enterKeyword;
                            } else if (!journeySet.value.imageId ||  journeySet.value.imageId.trim() === '') {
                                errorMessage = language.value.validation.selectIcon;
                            } else {
                                await execute(async () => {
                                    if (editingExisting.value) {
                                        await updateJourneySet(journeySet.value);
                                    } else {
                                        journeySet.value.appInstanceId = store.getters['app/selectedAppInstanceId'];
                                        journeySet.value.id = uuid4();
                                        await createJourneySet(journeySet.value);
                                    }
                                    await router.push({ name: 'journeySetJourneys', params: { journeySetId: journeySet.value.id }});
                                }, language.value.errors.savingJourneySet);
                                return;
                            }

                            presentValidationAlert(errorMessage);
                        }
                    )
                    .create()
            }),
        }
    }
});
</script>

<style scoped>
.custom-button {
    margin-bottom: 20px;
}

.image-wrapper {
    display: flex;
    justify-content: center;
}

.custom-content-layout {
    max-width: 80%;
}
</style>